<template>
  <v-container grid-list-xs>
    <v-layout row wrap>
      <v-flex xs12>
        <h3>TakeIn Support</h3>
      </v-flex>
    </v-layout>
    <ValidationObserver v-slot="{ valid }">
      <v-layout row wrap>
        <v-flex xs12 sm6 md4>
          <validation-provider name="Name" rules="required|min:3" mode="lazy" v-slot="{ errors }">
            <v-text-field
              label="Name"
              :disabled="submitted"
              :error-messages="errors"
              required
              :counter="50"
              v-model="name"
            ></v-text-field>
          </validation-provider>
        </v-flex>
      </v-layout>
      <v-layout row wrap>
        <v-flex xs12 sm6 md4>
          <validation-provider  name="email" rules="email" v-slot="{ errors }">
            <v-text-field

              label="Email"
              :disabled="submitted"
              :error-messages="errors"
              v-model="email"
            ></v-text-field>
            <span>{{ errors[0] }}</span>
          </validation-provider>
        </v-flex>
      </v-layout>
      <v-layout row wrap>
        <v-flex xs12 sm6 md4>
          <validation-provider
            name="messages"
            rules="required|min:10"
            v-slot="{ errors }"
          >
            <v-textarea
              :disabled="submitted"
              label="Detail description of issue"
              v-model="message"
              :counter="200"
              :error-messages="errors"
              required

              rows="6"
            ></v-textarea>
            <pre></pre>
          </validation-provider>
        </v-flex>
      </v-layout>

      <v-layout row wrap>
        <v-flex xs12>
          <v-btn @click="submit" class="primary" :disabled="!valid && submitted"
            >submit</v-btn
          >
          <v-btn @click="clear">clear</v-btn>
        </v-flex>
      </v-layout>

      <v-layout row wrap mt-4>
        <v-flex xs12>
          You can always reach us at: support@takein.com
        </v-flex>
      </v-layout>
    </ValidationObserver>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'

import { required, email,min } from 'vee-validate/dist/rules'
import { ValidationObserver, extend, setInteractionMode } from 'vee-validate'
setInteractionMode('lazy');


extend('email', email)
extend('min', min)
extend('required', required)

export default {
  components: {
    ValidationObserver
  },
  data() {
    return {
      name: null,
      email: null,
      message: null,
      valid: true,
      submitted: false
    }
  },

  computed: {
    ...mapGetters({
      loading: 'loading',
      profile: 'profile'
    })
  },
  mounted(){
    if (this.profile){
      this.copyFromProfile(this.profile)
    }
  },
  watch: {
    profile(){

      if (this.profile){
        this.copyFromProfile(this.profile)
      }
    }
  },
  methods: {
    submit() {
      this.submitted = true
      let note ={name:this.name, message:this.message, email:this.email}
      this.$store.dispatch('submitSupport',note )
      this.$store.dispatch('setMessage', {
        title: 'Thank you',
        body: 'We will get back to you shortly'
      })
      //    this.$validator.validateAll()
    },
    clear() {
      this.name = ''
      this.email = ''
      this.message = ''
    },
    copyFromProfile(profile) {
      this.name = profile.name
      this.email = profile.email

    }
  }
}
</script>
