var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"grid-list-xs":""}},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('h3',[_vm._v("TakeIn Support")])])],1),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
return [_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"xs12":"","sm6":"","md4":""}},[_c('validation-provider',{attrs:{"name":"Name","rules":"required|min:3","mode":"lazy"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Name","disabled":_vm.submitted,"error-messages":errors,"required":"","counter":50},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})]}}],null,true)})],1)],1),_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"xs12":"","sm6":"","md4":""}},[_c('validation-provider',{attrs:{"name":"email","rules":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Email","disabled":_vm.submitted,"error-messages":errors},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('span',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"xs12":"","sm6":"","md4":""}},[_c('validation-provider',{attrs:{"name":"messages","rules":"required|min:10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"disabled":_vm.submitted,"label":"Detail description of issue","counter":200,"error-messages":errors,"required":"","rows":"6"},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}}),_c('pre')]}}],null,true)})],1)],1),_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-btn',{staticClass:"primary",attrs:{"disabled":!valid && _vm.submitted},on:{"click":_vm.submit}},[_vm._v("submit")]),_c('v-btn',{on:{"click":_vm.clear}},[_vm._v("clear")])],1)],1),_c('v-layout',{attrs:{"row":"","wrap":"","mt-4":""}},[_c('v-flex',{attrs:{"xs12":""}},[_vm._v("\n        You can always reach us at: support@takein.com\n      ")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }